<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('globalTrans.excelImport') }}</h4>
            </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-col sm="12" md="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col>
                                    <ValidationProvider name="Table" vid='table_id' rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="table_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('globalTrans.selectTable')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="table_id"
                                            :options="tableTypeList"
                                            id="table_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col>
                                    <ValidationProvider name="Attachment file" vid="excel_data" rules="required">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="excel_data"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('globalTrans.excelFile')}}<span class="text-danger">*</span>
                                        </template>
                                        <b-form-file
                                        id="excel_data"
                                        v-model="excel_attachment"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        v-on:change="onFileChange"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-file>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                            </b-row>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
                                        <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                                        <span v-else>{{ saveBtnName }}</span>
                                    </b-button>
                                </div>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { irrigationExcelImport } from '../../api/routes'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.import'),
      table_id: 0,
      excel_data: null,
      attachment: '',
      isLoading: false,
      excel_attachment: []
    }
  },
  computed: {
      tableTypeList () {
         const dataen = [
             {
                text: 'Project Information',
                value: 1
            },
            {
                text: 'Contractor Information',
                value: 2
            },
            {
                text: 'Scheme Type Info',
                value: 3
            },
            {
                text: 'Scheme Form Affidavit',
                value: 4
            },
            {
                text: 'Circle Area Info ',
                value: 5
            },
            {
                text: 'Store Item Category',
                value: 6
            },
            {
                text: 'Store Item Sub Category',
                value: 7
            },
            {
                text: 'Measurement Unit Info',
                value: 8
            },
            {
                text: 'Store Item Info',
                value: 9
            },
            {
                text: 'Pump Installation Progress Type',
                value: 10
            },
            {
                text: 'Laboratory Information',
                value: 11
            },
            {
                text: '(Scheme Payment)-BADC',
                value: 12
            },
            {
                text: '(Scheme Payment)-BMDA',
                value: 21
            },
            {
                text: 'Pump Operator Payment)-All',
                value: 13
            },
            {
                text: '(Smart Card Payment)-All',
                value: 14
            },
            {
                text: '(Water Testing Payment)-All',
                value: 15
            },
            {
                text: 'Pump Information ',
                value: 16
            },
            {
                text: 'Pump Operator Information',
                value: 17
            },
            {
                text: 'Hardware Dealer Information',
                value: 18
            },
            {
                text: 'Shops Information',
                value: 19
            },
            {
                text: 'Mechanics Information',
                value: 20
            },
            {
                text: 'Farmer Land Details',
                value: 22
            }
         ]
         const databn = [
             {
                text: 'প্রকল্প সম্পর্কিত তথ্য',
                value: 1
            },
            {
                text: 'ঠিকাদার তথ্য',
                value: 2
            },
            {
                text: 'প্রকল্পের ধরণের তথ্য',
                value: 3
            },
            {
                text: 'প্রকল্পের ফর্ম হলফনামা',
                value: 4
            },
            {
                text: 'সার্কেল এরিয়া তথ্য',
                value: 5
            },
            {
                text: 'স্টোর আইটেম বিভাগ',
                value: 6
            },
            {
                text: 'স্টোর আইটেম সাব বিভাগ',
                value: 7
            },
            {
                text: 'পরিমাপ ইউনিট তথ্য',
                value: 8
            },
            {
                text: 'স্টোর আইটেম তথ্য',
                value: 9
            },
            {
                text: 'পাম্প ইনস্টলেশন অগ্রগতি প্রকার',
                value: 10
            },
            {
                text: 'পরীক্ষাগার সম্পর্কিত তথ্য',
                value: 11
            },
            {
                text: '(প্রকল্পের অর্থ প্রদান) -বিএডিসি',
                value: 12
            },
            {
                text: '(প্রকল্পের অর্থ প্রদান) -বিএমডিএ',
                value: 21
            },
            {
                text: '(পাম্প অপারেটর প্রদান) - সমস্ত',
                value: 13
            },
            {
                text: '(স্মার্ট কার্ড পেমেন্ট) - সমস্ত',
                value: 14
            },
            {
                text: '(জল পরীক্ষার অর্থ প্রদান) - সমস্ত',
                value: 15
            },
            {
                text: 'পাম্প তথ্য',
                value: 16
            },
            {
                text: 'পাম্প অপারেটরের তথ্য',
                value: 17
            },
            {
                text: 'হার্ডওয়্যার ডিলারের তথ্য',
                value: 18
            },
            {
                text: 'দোকান সম্পর্কিত তথ্য',
                value: 19
            },
            {
                text: 'যান্ত্রিক তথ্য',
                value: 20
            },
            {
                text: 'কৃষকের জমির বিবরণ',
                value: 22
            }
         ]
         const data = this.$i18n.locale === 'bn' ? databn : dataen
         return data
      }
  },
  methods: {
    onFileChange (e) {
      this.attachment = e.target.files[0]
    },
     async createData () {
        this.isLoading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        var formData = new FormData()

        formData.append('table_id', this.table_id)
        formData.append('excel_file', this.attachment)

        result = await RestApi.postData(irriSchemeServiceBaseUrl, irrigationExcelImport, formData, config)

        this.excel_attachment = []

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
            this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.excelImportMessage'),
            color: '#D6E09B'
            })
            this.isLoading = false
            this.$refs.form.reset()
            this.table_id = 0
            this.attachment = ''
        } else {
            this.isLoading = false
            if (result.errors) {
                this.$refs.form.setErrors(result.errors)
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: result.message
                })
            }
        }
    }
  }
}
</script>
